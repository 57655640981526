import { HeroItemModel } from '@model/hero.model';
import { ForwardRefExoticComponent, RefAttributes, useEffect, useMemo, useState } from 'react';
import { IdleHeroSectionProps } from '@organisms/HeroParagraph/IdleHeroSection/IdleHeroSection.props';

type IdleHeroType = ForwardRefExoticComponent<IdleHeroSectionProps & RefAttributes<HTMLDivElement>>;
type ActiveHeroType = ForwardRefExoticComponent<HeroItemModel & RefAttributes<HTMLDivElement>>;

interface UseHeroProps {
  IdleHero: IdleHeroType;
  ActiveHero: ActiveHeroType;
  heroes: HeroItemModel[];
  slideTimeout: number;
}

interface UseHeroReturn {
  $heroes: JSX.Element[];
  currentHero: HeroItemModel;
}

export const useHeroes = ({ IdleHero, ActiveHero, heroes, slideTimeout }: UseHeroProps): UseHeroReturn => {
  const [activeHeroIndex, setActiveHeroIndex] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>(null);

  useEffect(() => {
    if (!heroes) return;

    const id = setInterval(() => {
      setActiveHeroIndex((currentIndex) => (currentIndex + 1) % heroes.length);
    }, slideTimeout);

    setIntervalId(id);
    return () => clearInterval(id);
  }, [heroes]);

  const changeSlideHandler = (index: number) => {
    setActiveHeroIndex(index);
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const $heroes = useMemo(
    () =>
      heroes.map((hero, index) => {
        if (index === activeHeroIndex) {
          return <ActiveHero {...hero} key={hero.id} />;
        }

        return (
          <IdleHero
            idleHero={hero}
            key={hero.title}
            slideTimeout={slideTimeout}
            changeHeroIndex={() => changeSlideHandler(index)}
            isAutoUpdateStopped={!intervalId}
            isAfterActiveHero={index > activeHeroIndex}
            isRightAfterHero={activeHeroIndex + 1 === index}
          />
        );
      }),
    [activeHeroIndex, intervalId, heroes]
  );

  return {
    $heroes,
    currentHero: heroes[activeHeroIndex],
  };
};
