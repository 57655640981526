import { forwardRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { AnimatePresence } from 'framer-motion';
import IdleHeroSection from '@organisms/HeroParagraph/IdleHeroSection/IdleHeroSection';
import ActiveHeroSection from '@organisms/HeroParagraph/ActiveHeroSection/ActiveHeroSection';
import { HeroItemModel, HeroModel } from '@model/hero.model';
import { IdleHeroSectionProps } from '@organisms/HeroParagraph/IdleHeroSection/IdleHeroSection.props';
import { FieldParagraphProps } from '@model/paragraph-props.model';
import { useHeroes } from '@organisms/HeroParagraph/useHeroes';
import { useColors } from '@hooks/useColors';
import { absoluteUrl, transformTitleId } from '@utils/functions/utils';

const Grid = dynamic(() => import('@mui/material/Grid'));
const Box = dynamic(() => import('@mui/material/Box'));
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'));
const Row = dynamic(() => import('@atoms/CustomGrid/Row'));
const FadeIn = dynamic(() => import('@atoms/FadeIn'));
const GridMask = dynamic(() => import('@atoms/GridMask/GridMask'));
const HeroBackgroundImage = dynamic(() => import('@organisms/HeroParagraph/HeroBackgroundImage/HeroBackgroundImage'));

// Not working with dynamic import:
// const IdleHeroSection = dynamic(() => import('@organisms/HeroParagraph/IdleHeroSection/IdleHeroSection'));
// const ActiveHeroSection = dynamic(() => import('@organisms/HeroParagraph/ActiveHeroSection/ActiveHeroSection'));

const checkVisibility = (locale: string, field_language_visibility?: 'any' | null | string) =>
  !field_language_visibility || field_language_visibility === 'any' || field_language_visibility === locale;

const HeroParagraph = ({ fieldParagraph }: FieldParagraphProps) => {
  const { locale } = useRouter();

  const showContent = checkVisibility(locale, fieldParagraph?.field_language_visibility);

  const data = useMemo(() => {
    const newData: HeroModel = {
      anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
      direction: 'middle',
      colorVariant: fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
      items: fieldParagraph.field_items?.map((item) => ({
        id: item.id,
        title: item.field_description?.value,
        overline: item?.field_title,
        cta: {
          label: item.field_link?.title,
          url: item.field_link?.uri,
          isExternal: false,
          ...item.field_link,
        },
        imageUrl: absoluteUrl(item.field_media?.field_media_image.uri.url),
      })),
    };

    return newData;
  }, [fieldParagraph]);

  const heroes = useMemo(() => {
    return data ? data.items : [];
  }, [data]);

  const { $heroes, currentHero } = useHeroes({
    IdleHero: forwardRef<HTMLDivElement, IdleHeroSectionProps>(function IdleHero(props, ref) {
      return <IdleHeroSection ref={ref} {...props} />;
    }),

    ActiveHero: forwardRef<HTMLDivElement, HeroItemModel>(function ActiveHero(props, ref) {
      return <ActiveHeroSection ref={ref} {...props} />;
    }),

    slideTimeout: 5000,

    heroes,
  });

  const { backgroundColor, textColor } = useColors(data?.colorVariant);

  if (!data || !showContent) return null;

  return (
    <GridContainer
      anchor-data={transformTitleId(data.anchorData || '')}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        py: { xs: 80, lg: 104 },
        backgroundColor,
        minHeight: { xs: 620 + 80 * 2, lg: 620 + 104 * 2 },
      }}
    >
      <GridMask direction={data.direction} colorVariant={data.colorVariant} />
      <FadeIn>
        <Row>
          <Grid item xs={12}>
            <Box position="relative" pt={{ xs: 40, lg: 56 }}>
              <AnimatePresence mode="popLayout" initial={false}>
                {currentHero && <HeroBackgroundImage key={currentHero.id} imageUrl={currentHero.imageUrl || ''} />}
              </AnimatePresence>
            </Box>
          </Grid>
        </Row>

        <Row>
          <Grid item xs={0} lg={1} display={{ xs: 'none', lg: 'block' }} />

          <Grid item xs={12} md={10} lg={8} xl={6}>
            <AnimatePresence mode="popLayout" initial={false}>
              {$heroes}
            </AnimatePresence>
          </Grid>
        </Row>
      </FadeIn>
    </GridContainer>
  );
};

export default HeroParagraph;
