import { HeroItemModel } from '@model/hero.model';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomButton from '@atoms/CustomButton/CustomButton';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

const ActiveHeroSection = forwardRef<HTMLDivElement, HeroItemModel>(function ActiveHeroSection(props, ref) {
  const { title, overline, cta, imageUrl, id } = props;

  const isDesktopViewport = useMediaQuery('(min-width: 1400px)');

  return (
    <>
      <motion.div
        ref={ref}
        key={`${id}-box`}
        transition={{ duration: 0.5 }}
        style={{ transformOrigin: isDesktopViewport ? 0 : 27 }}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5 }}
      >
        <Box position="relative" my={16} px={{ xs: 27, lg: 0 }}>
          <Typography color="common.white" variant="overlineMono" component="p" mb={16}>
            {overline}
          </Typography>

          <Typography variant="h3" color="common.white" mb={32} dangerouslySetInnerHTML={{ __html: title }} />

          <CustomButton
            text={cta?.label}
            component="a"
            variant="large"
            fullWidth={false}
            color="primary"
            href={cta?.url}
            external={cta?.isExternal}
          />
        </Box>
      </motion.div>
    </>
  );
});

export default ActiveHeroSection;
