import Box from '@mui/material/Box';
import CustomButton from '@atoms/CustomButton/CustomButton';
import Typography from '@mui/material/Typography';
import { IdleHeroSectionProps } from '@organisms/HeroParagraph/IdleHeroSection/IdleHeroSection.props';
import { forwardRef, MutableRefObject, useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './IdleHeroSection.module.scss';
import dayjs from 'dayjs';

const IdleHeroSection = forwardRef<HTMLDivElement, IdleHeroSectionProps>(function IdleHeroSection(
  { idleHero, slideTimeout, isAutoUpdateStopped, isRightAfterHero, isAfterActiveHero, changeHeroIndex },
  ref: MutableRefObject<HTMLDivElement>
) {
  useEffect(() => {
    if (!isRightAfterHero || isAutoUpdateStopped) return;

    const time = dayjs();
    const updateHeight = () => {
      if (isAutoUpdateStopped || !ref.current) return;

      const diff = dayjs().diff(time, 'milliseconds');

      if (diff >= slideTimeout) {
        return;
      }

      ref.current.style.setProperty('--hero-divider-height', `${(diff / slideTimeout) * 100}%`);

      requestAnimationFrame(updateHeight);
    };

    updateHeight();
  }, [isRightAfterHero, isAutoUpdateStopped, slideTimeout]);

  return (
    <motion.div
      ref={ref}
      style={{ transformOrigin: 'left' }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box position="relative" px={{ xs: 27, lg: 0 }} sx={{ zIndex: 1 }}>
        {isAfterActiveHero && <Box className={`${styles.Divider} ${isRightAfterHero ? styles.ActiveDivider : ''}`} />}

        <CustomButton
          text={
            <Typography
              variant={'bodyL'}
              sx={{ textTransform: 'initial' }}
              dangerouslySetInnerHTML={{ __html: idleHero.title }}
            />
          }
          disableRipple
          sx={{ textAlign: 'left', color: 'common.white' }}
          variant="link"
          color="link"
          type="button"
          onClick={changeHeroIndex}
        />
        {!isAfterActiveHero && <Box className={`${styles.Divider} ${styles.BeforeHero}`} />}
      </Box>
    </motion.div>
  );
});

export default IdleHeroSection;
